.header {
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 3%;
    padding-right: 3%;
    position: absolute;
    width: 100%;
    height: 13vw;
    align-items: center;
    position: fixed;
    transition: 0.5s ease-in-out;
    z-index: 30;
}

.header_active {
    background-color: rgba(0, 0, 0, 0.8);
    /* background-color: black; */
    display: flex;
    justify-content: space-between;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left: 3%;
    padding-right: 3%;
    position: absolute;
    width: 100%;
    height: 13vw;
    align-items: center;
    position: fixed;
    transition: 0.5s ease-in-out;
    z-index: 60;
}

.header_logo_hidden {
    height: 0vw;
    width: 0vw;
}

.header_logo {
    height: 20vw;
    width: 20vw;
    z-index: 60;
}

.inquiry_btn {
    background-color: transparent;
    border: solid 1px;
    border-color: white;
    color: white;
    padding-top: 0.3%;
    padding-bottom: 0.3%;
    padding-left: 3%;
    padding-right: 3%;
    outline: none;
    z-index: 40;
}

.download_dropdown_Header {
    background-color: transparent;
    outline: none;
    width: 80%;
    appearance: none;
}

.inquiry_btn:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

@media only screen and (min-device-width: 601px) and (max-device-width: 900px) {
    .header {
        height: 10vw;
    }

    .header_active {
        height: 10vw;
    }

    .header_logo {
        height: 20vw;
        width: 20vw;
    }
}

@media only screen and (min-device-width: 900px) and (max-device-width: 1500px) {
    .header {
        height: 5vw;
    }

    .header_active {
        height: 5vw;
    }

    .header_logo {
        height: 8vw;
        width: 8vw;
    }

    .inquiry_btn {
        padding-top: 0.3%;
        padding-bottom: 0.3%;
        padding-left: 2%;
        padding-right: 2%;
    }
}

@media only screen and (min-device-width: 1500px) {
    .header {
        height: 5vw;
    }

    .header_active {
        height: 5vw;
    }

    .header_logo {
        height: 10vw;
        width: 10vw;
    }

    .inquiry_btn {
        padding-top: 0%;
        padding-bottom: 0%;
        padding-left: 2%;
        padding-right: 2%;
    }
}

.bar1,
.bar2,
.bar3 {
    width: 25px;
    height: 2px;
    background-color: white;
    margin: 6px 0;
    transition: 0.4s;
    border-radius: 5vw;
    z-index: 50;
}

.change .bar1 {
    transform: translate(0, 5px) rotate(-45deg);
    z-index: 50;
}

.change .bar2 {
    opacity: 0;
}

.change .bar3 {
    transform: translate(0, -11px) rotate(45deg);
    z-index: 50;
}

.change {
    background-color: transparent;
    color: white;
    outline: none;
    z-index: 50;
}

.menu_bg {
    background: linear-gradient(to right bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.6));
    transition: 0.8s ease-in-out;
    overflow-y: auto;
}

.underscore {
    visibility: hidden;
}

.overlay_money_menu {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.0);
    z-index: 1;
}

.popup_money_menu {
    height: auto;
    width: auto;
    display: block;
    position: absolute;
    left: 51%;
    top: -65%;
    transform: translate(-50%, 50%);
    background-color: white;
    padding: 10px;
    z-index: 2;
    border-radius: 5px;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.overlay_language_menu {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.0);
    z-index: 1;
}

.popup_language_menu {
    height: auto;
    width: auto;
    display: block;
    position: absolute;
    left: 65%;
    top: -180%;
    transform: translate(-50%, 50%);
    background-color: white;
    padding: 10px;
    z-index: 2;
    border-radius: 5px;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* .overlay_signIn_menu {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.0);
    z-index: 1;
} */

/* .popup_signIn_menu {
    height: auto;
    width: 20%;
    display: block;
    position: absolute;
    left: 78%;
    top: 2%;
    transform: translate(-50%, 50%);
    background-color: white;
    padding: 1%;
    z-index: 2;
    border-radius: 0.8vw;
    color: black;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
} */

@media only screen and (min-device-width: 601px) and (max-device-width: 900px) {
    .popup_money_menu {
        width: 20%;
    }

    .popup_language_menu {
        width: 20%;
    }

    /* .popup_signIn_menu {
        width: 20%;
    } */
}

@media only screen and (min-device-width: 900px) and (max-device-width: 1500px) {
    .popup_money_menu {
        left: 80%;
        top: -42%;
    }

    .popup_language_menu {
        left: 85%;
        top: -122%;
    }

    /* .popup_signIn_menu {
        width: 10%;
        left: 91.5%;
        top: -3%;
    } */
}

@media only screen and (min-device-width: 1500px) {
    .popup_money_menu {
        left: 84%;
        top: -20%;
    }

    .popup_language_menu {
        left: 88%;
        top: -84%;
    }
}

/* Footer css from here */
.download_dropdown {
    background-color: #232323;
    outline: none;
    width: 80%;
    appearance: none;
}

/* Footer css ends here */