.Splash{
    cursor : pointer;
    position: fixed;
    top: 50%;
    left: 50%;
    height: 100%;
    width: 100%;
    transform: translate(-50%, -50%);
    transition: all ease-in-out 600ms;
    z-index: 80;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}



.logo{
    height: 12vw;
}